import Vue from 'vue';
import Router from 'vue-router';
import store from '@/core/services/store';

//for NavigationDuplicated error.
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

// Import middleware
import verifyRoute from '@/middleware/verifyRoute';
import verifyUser from '@/middleware/verifyUser';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/',
          name: 'anasayfa',
          meta: {
            title: 'Ana Sayfa | EngageMetriq CRM',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/Dashboard.vue'),
        },
        {
          path: '/bize-ulasin',
          name: 'bize-ulasin',
          meta: {
            title: 'Bize Ulaşın | EngageMetriq CRM',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/ContactUs.vue'),
        },
        {
          path: '/sistem-kullanicilari',
          component: () => import('@/view/pages/systemUsers/Home.vue'),
          children: [
            {
              path: '',
              name: 'systemUsers',
              meta: {
                title: 'Sistem Kullanıcı Listesi | EngageMetriq CRM',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/systemUsers/List.vue'),
            },
          ],
        },
        {
          path: '/urunler',
          component: () => import('@/view/pages/products/Home.vue'),
          children: [
            {
              path: '',
              name: 'products',
              meta: {
                title: 'Ürün Listesi | EngageMetriq CRM',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/products/List.vue'),
            },
          ],
        },
        {
          path: '/musteri-yonetim-paneli',
          component: () =>
            import('@/view/pages/customerManagementPanel/Home.vue'),
          children: [
            {
              path: '',
              name: 'customerManagementPanel',
              meta: {
                title: 'Müşteri Yönetim Paneli | EngageMetriq CRM',
                middleware: [verifyUser],
              },
              component: () =>
                import('@/view/pages/customerManagementPanel/List.vue'),
            },
            {
              path: ':companyId/calisanlar',
              component: () => import('@/view/pages/companyEmployees/Home.vue'),
              children: [
                {
                  path: '',
                  name: 'companyEmployees',
                  meta: {
                    title: 'Çalışanlar Listesi | EngageMetriq CRM',
                    middleware: [verifyUser],
                  },
                  component: () =>
                    import('@/view/pages/companyEmployees/List.vue'),
                },
              ],
            },
            {
              path: ':companyId/urunler',
              component: () => import('@/view/pages/products/Home.vue'),
              children: [
                {
                  path: '',
                  name: 'companyProducts',
                  meta: {
                    title: 'Ürün Listesi | EngageMetriq CRM',
                    middleware: [verifyUser],
                  },
                  component: () =>
                    import(
                      '@/view/pages/customerManagementPanel/ProductList.vue'
                    ),
                },
              ],
            },
            {
              path: ':companyId/urunler/:productId/rapor',
              component: () => import('@/view/pages/products/Home.vue'),
              children: [
                {
                  path: '',
                  name: 'companyProductReports',
                  meta: {
                    title: 'Ürün Raporu | EngageMetriq CRM',
                    middleware: [verifyUser],
                  },
                  component: () =>
                    import(
                      '@/view/pages/customerManagementPanel/ProductReport.vue'
                    ),
                },
              ],
            },
          ],
        }, 
      ],
    },
    {
      path: '/',
      component: () => import('@/view/layout/Auth'),
      children: [
        {
          path: '/login',
          name: 'login',
          meta: {
            title: 'Giriş - EngageMetriq Manage Platform',
            middleware: [verifyRoute],
          },
          component: () => import('@/view/pages/auth/Login'),
        },
        {
          path: '/activation/user/:code',
          name: 'UserActivation',
          meta: {
            title: 'Kullanıcı Doğrulama - EngageMetriq Manage Platform',
            middleware: [verifyRoute],
          },
          component: () => import('@/view/pages/auth/UserActivation'),
        },
        {
          path: '/forgot-password',
          name: 'forgotPassword',
          meta: {
            title: 'Şifremi Unuttum - EngageMetriq Manage Platform',
            middleware: [verifyRoute],
          },
          component: () => import('@/view/pages/auth/ForgotPassword'),
        },
        {
          path: '/reset-password/:code',
          name: 'resetPassword',
          meta: {
            title: 'Şifre Sıfırla - EngageMetriq Manage Platform',
            middleware: [verifyRoute],
          },
          component: () => import('@/view/pages/auth/ResetPassword'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      meta: {
        title: 'Sayfa bulunamadı! | EngageMetriq CRM',
      },
      component: () => import('@/view/pages/error/Error.vue'),
    },
  ],
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      store,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  } else {
    return next();
  }
});
export default router;
