export default function generateNotification(
  app,
  variant,
  text = '',
  title = ''
) {
  if (title === '') {
    if (variant === 'success') title = 'Başarılı.';
    else title = 'Üzgünüz, bir hata oluştu.';
  }
  app.$swal.fire(title, text, variant);
}

/*

https://sweetalert2.github.io/#download

      this.$swal("Hello Vue world!!!");
      this.$swal.fire("Hello Vue world!!!", "sub title", "success");

      variants : success , error, warning, info, question

*/
