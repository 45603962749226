import ApiService from '@/core/services/api.service';

const dbFunctions = {
  get(url) {
    return new Promise((resolve, reject) => {
      ApiService.get(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  insertOrUpdate(url, id, payload) {
    return new Promise((resolve, reject) => {
      if (id == '' || id == 'new' || id === undefined) {
        ApiService.post(url, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        payload.id = id;
        ApiService.put(url, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },
  update(url, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(url, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delete(url, ids) {
    return new Promise((resolve, reject) => {
      let params = {
        ids: Array.isArray(ids) ? ids : [ids],
      };
      ApiService.delete(url, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadForm(url, formData) {
    return new Promise((resolve, reject) => {
      ApiService.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setResponse(app, response, customTitle = '', customMessage = '') {
    if (response && response.response && response.response.data)
      response = response.response.data;

    if (response.error == false && response.success == true) {
      app.$generateNotification(
        app,
        'success',
        customMessage ? customMessage : 'İşlem başarıyla tamamlandı.',
        customTitle ? customTitle : ''
      );
      return response.data;
    } else if (response.error == true && response.errors.length > 0) {
      for (let index = 0; index < response.errors.length; index++) {
        app.$generateNotification(app, 'error', response.errors[index].message);
      }
    } else {
      app.$generateNotification(
        app,
        'error',
        'Bir hata oluştu, lütfen daha sonra tekrar deneyin.'
      );
    }
    return;
  },
};

export default dbFunctions;
