import {
  LOGOUT,
  GET_MY_USER,
  VERIFY_AUTH,
} from '@/core/services/store/auth.module';
import JwtService from '@/core/services/jwt.service';

export default function verifyUser({ next, store, router }) {
  const currentUser = JwtService.getCurrentUser();

  if (currentUser) {
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

    store
      .dispatch(GET_MY_USER)
      .then((res) => {
        if (res.success == true && res.error == false) {
          //Control url

          let path = window.location.pathname;
          // if (res.data.userType === 'SystemAdministrator') {
          //   if (
          //     !path.startsWith('/anasayfa') &&
          //     !path.startsWith('/sistem-kullanicilari') &&
          //     !path.startsWith('/musteri-yonetim-paneli')
          //   ) {
          //     router.push({ name: 'anasayfa' });
          //   }
          // } else if (res.data.userType === 'Company') {
          //   if (
          //     !path.startsWith('/anasayfa') &&
          //     !path.startsWith('/yardim-merkezi') &&
          //     !path.startsWith('/bize-ulasin')
          //   ) {
          //     router.push({ name: 'anasayfa' });
          //   }
          // } else {
          //   if (
          //     path.startsWith('/sistem-kullanicilari') ||
          //     path.startsWith('/musteri-yonetim-paneli')
          //   ) {
          //     router.push({ name: 'anasayfa' });
          //   }
          // }
        } else {
          store.dispatch(LOGOUT);
          router.push({ name: 'login' });
        }
      })
      .catch((err) => {
        store.dispatch(LOGOUT);
        router.push({ name: 'login' });
      });
  } else {
    store.dispatch(LOGOUT);
    router.push({ name: 'login' });
  }

  return next();
}
